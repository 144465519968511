<template>
  <div class="orange-bg">
    <img alt="bg" src="/assets/images/bg.jpg" />
    <div class="orange-bg__glass"></div>
  </div>
</template>

<script>
export default {
  name: "Orange",
};
</script>

<style scoped></style>
