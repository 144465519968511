<template>
  <div class="orange">
    <!-- Start of main-bg -->
    <Orange />
    <!-- End of main-bg -->

    <!-- Start of Navbar -->
    <Nav />
    <!-- End of Navbar -->

    <!-- Start of content -->
    <router-view />
    <!-- End of content -->
  </div>
</template>

<script>
import Nav from "@/layouts/Orange/Nav";
import Orange from "@/layouts/Orange/Orange";

export default {
  name: "OrangeLayout",
  components: {
    Nav,
    Orange,
  },
};
</script>
