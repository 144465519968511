<template>
  <nav class="nav">
    <div class="container">
      <router-link id="logo" class="nav-logo" :to="{ name: 'home' }">
        <img alt="logo" src="/assets/images/logo.png" />
      </router-link>
      <ul class="nav__menu" v-if="!loggedIn">
        <li class="nav__menu-item">
          <router-link class="nav__menu-link" :to="{ name: 'login' }">
            Kirish
          </router-link>
        </li>
        <li class="nav__menu-item">
          <a class="nav__menu-link" href="/teacher"> O'qituvchi </a>
        </li>
      </ul>
      <ul class="nav__menu" v-else>
        <li class="nav__menu-item nav__menu-dropdown">
          <span class="nav__menu-link">
            <span> {{ user.username }} </span>
          </span>
          <div class="nav__menu-dropdown-inner">
            <router-link
              :to="{ name: 'user_account' }"
              class="nav__menu-dropdown-link"
            >
              Profil
            </router-link>
            <a class="nav__menu-dropdown-link" @click.prevent="logout">
              logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  computed: {
    loggedIn: function () {
      return this.$store.getters["user/loggedIn"];
    },
    user: function () {
      return this.$store.getters["user/getUser"];
    },
  },
  methods: {
    logout: function () {
      if (confirm("Tizmidan chiqmoqchimis?")) {
        this.$store.dispatch("user/logout");
      }
    },
  },
};
</script>
